import React, { useContext, useState } from "react";
import { Context as IntlContext } from "context/Intl";
import Loader from "ui/Loader";

import InformationService from "dataProvider/InformationService";
import LegalDetails from "./LegalDetails";
import CompanyDetails from "./CompanyDetails";
import RequestChangeButton from "ui/RequestChangeButton";

const InformationTab: React.FC = () => {
    const intl = useContext(IntlContext);
    const [ loading, setLoading ] = useState<LoadingStatus>("new");
    const [ userData, setUserData ] = useState<UserData>();

    if (loading === "new") {
        setLoading("loading");

        const data = InformationService.user();
        data.then((data: any) => {
            setUserData(data);
            setLoading("success");
        }).catch(() => {
            setLoading("error");
        });
    }

    if (loading === "loading" || loading === "new") {
        return <Loader message={ intl.formatMessage("generic.loading") } />;
    }

    if (loading === "error" || !userData) {
        return <span>{ intl.formatMessage("error.generic") }</span>;
    }

    return (
        <React.Fragment>
            <div className="grid">
                <div className="grid-2">
                    <CompanyDetails userData={ userData } />
                </div>

                <div className="grid-3">
                    <LegalDetails userData={ userData } />
                </div>
            </div>

            <RequestChangeButton subject="BO e-commerce - Demande de modification - Informations">
                { intl.formatMessage("generic.requestChange") }
            </RequestChangeButton>
        </React.Fragment>
    );
};

export default InformationTab;

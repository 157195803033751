import fetch, { parseResponse } from "./fetch";

export interface AuthData {
    user: {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        username: string;
        language_code: string;
    };
    capabilities: Array<string>;
}

class Authentication {
    login(username: string, password: string) {
        return parseResponse<AuthData>(
            fetch.post(`authentication/login/`, {
                username,
                password,
            })
        );
    }

    logout() {
        return parseResponse(fetch.post(`authentication/logout/`));
    }

    fresh() {
        return parseResponse<AuthData>(fetch.get(`authentication/fresh/`));
    }

    requestCode(code: string) {
        return parseResponse<AuthData>(
            fetch.post(`authentication/requestCode/`, {
                code
            })
        );
    }

    loginUsingCode(code: string, login_code: string) {
        return parseResponse<AuthData>(
            fetch.post(`authentication/loginCode/`, {
                code,
                login_code,
            })
        );
    }
}

export default new Authentication();

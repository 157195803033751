import React from "react";

export type TabProps = {
    label: string;
    route?: string;
};

const Tab: React.FC<TabProps> = ({ children }) => {
    return (
        <React.Fragment>{ children }</React.Fragment>
    );
};
export default Tab;

import React from "react";
import DataTableHeader from "./Header";
import DataTableBody from "./Body";
import DataTablePagination from "./Pagination";

export interface Column {
    key: string;
    label: string;
    sortable?: boolean;
}

export interface Action {
    key: string;
    icon: string;
    label?: string;
    onClick: (item: any) => any;
    condition: (item: any) => boolean;
}

interface Props {
    columns: Column[];
    data: PaginatedResults<any>;
    renderer?: (key: any, datas: any) => any;
    actions?: Action[];
    queryParams?: QueryParam;
    setQueryParams?: QueryParamSetter;
    pageTag?: string;
}

const DataTable: React.FC<Props> = (props: Props) => {
    const { columns, data, renderer, actions, queryParams, setQueryParams, pageTag } = props;

    return (
        <React.Fragment>
            <table className="with-clean-lines">
                <DataTableHeader
                    columns={ columns }
                    actions={ actions }
                    queryParams={ queryParams }
                    setQueryParams={ setQueryParams }
                />

                <DataTableBody
                    columns={ columns }
                    data={ data.items }
                    renderer={ renderer }
                    actions={ actions }
                />
            </table>

            <DataTablePagination data={ data } pageTag={ pageTag } />
        </React.Fragment>
    );
};

export default DataTable;

import { IntlShape } from "context/Intl/Context";
import timestampToDate from "helpers/renderer/timestampToDate";
import Money from "ui/Mango/Money";
import TransactionCode from "ui/Mango/TransactionCode";
import TransactionStatus from "ui/Mango/TransactionStatus";
import TransactionType from "ui/Mango/TransactionType";

// Defines which colyumns to display
const getColumns = (intl: IntlShape) => {
    return [
        { key: "ExecutionDate", label: intl.formatMessage("transactions.list.label.executionDate"), sortable: true },
        { key: "Id", label: intl.formatMessage("transactions.list.label.id") },
        { key: "Type", label: intl.formatMessage("transactions.list.label.type") },
        { key: "Amount", label: intl.formatMessage("transactions.list.label.amount") },
        { key: "Status", label: intl.formatMessage("transactions.list.label.status") },
        { key: "Result", label: intl.formatMessage("transactions.list.label.result") },
    ];
}

// Set how the different columns will render
const getRenderer = (intl: IntlShape) => {
    return (key: any, datas: TransactionData) => {
        switch (key) {
            case "ExecutionDate":
                return timestampToDate(intl, datas.ExecutionDate, {
                    dateStyle: "long",
                    timeStyle: "short",
                });

            case "Type":
                return <TransactionType type={ datas.Type } />

            case "Amount":
                return <Money amount={ datas.DebitedFunds } fees={ datas.Fees } />

            case "Status":
                return <TransactionStatus status={ datas.Status } />

            case "Result":
                return <TransactionCode code={ datas.ResultCode } />
        }

        return datas[key as keyof TransactionData];
    }
}


// Define our data table actions
const getActions = (intl: IntlShape, displayModalPayout: any, displayModalRefund: any) => {
    return [
        {
            key: "payout",
            label: intl.formatMessage("transactions.list.action.payout"),
            icon: "lnr-exit-up",
            onClick: (item: TransactionData) => displayModalPayout(item),
            condition: (item: TransactionData) => {
                return item.Type !== "PAYOUT" && item.Nature === "REGULAR" && item.Status === "SUCCEEDED";
            },
        },
        {
            key: "refund",
            label: intl.formatMessage("transactions.list.action.refund"),
            icon: "lnr-undo",
            onClick: (item: TransactionData) => displayModalRefund(item),
            condition: (item: TransactionData) => {
                return item.Type !== "PAYOUT" && item.Nature === 'REGULAR' && item.Status === 'SUCCEEDED';
            },
        },
    ];
}

export {
    getRenderer,
    getColumns,
    getActions
};

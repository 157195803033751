import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import { Action, Column } from ".";
import DataTableAction from "./Action";

interface Props {
    columns: Column[];
    data: any;
    renderer?: (key: string, datas: any) => string;
    actions?: Action[];
}

const DataTableBody: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { columns, data, renderer, actions } = props;

    if (!data || !data.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={ columns.length } className="align-center">{ intl.formatMessage("generic.datalist.noResult") }</td>
                </tr>
            </tbody>
        )
    }

    return (
        <tbody>
            { data.map((row: any) => (
                <tr key={ Math.random() }>
                    { columns.map((column) => {
                        let content = row[column.key];
                        if (renderer) {
                            content = renderer(column.key, row);
                        }

                        return <td key={ Math.random() } className={ column.key }>{ content }</td>;
                    }) }

                    { (actions && !!actions.length) &&
                        <td className="table-actions">
                            { actions.map((action) => (
                                <DataTableAction
                                    { ...action }
                                    item={ row }
                                />
                            )) }
                        </td>
                    }
                </tr>
            )) }
        </tbody>
    );
};

DataTableBody.defaultProps = {
    actions: [],
}

export default DataTableBody;

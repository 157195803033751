import React, { useContext } from "react";
import { IntlContext } from "context";

interface Props {
    code: string;
}

const TransactionCode: React.FC<Props> = (props: Props) => {
    const { code } = props;
    const intl = useContext(IntlContext);

    return (
        <span className="mango-transaction-code">
            { intl.formatMessage('transactions.mango.errorCode.' + code) }
        </span>
    );
};

export default TransactionCode;

export default function errorFormatter(payload: any, defaultError: string) {
    let errorMessage = defaultError;

    let data = payload.response.data.data;
    if (data) {
        const keys = Object.keys(data);
        if (keys.length) {
            errorMessage = "error." + data[keys[0]].code;
        }
    } else {
        data = payload.response.data.error;
        errorMessage = data.type;
    }

    return errorMessage;
}

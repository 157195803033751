import React from "react";
import { Action } from ".";

interface Props extends Action {
    item: any;
}

const DataTableAction: React.FC<Props> = (props: Props) => {
    const { icon, label, onClick, condition, item } = props;

    if (condition && !condition(item)) {
        return null;
    }

    return (
        <span className={ "data-table-action" } title={ label } onClick={ () => onClick(item) }>
            <i className={ `lnr ${icon}` }></i>
        </span>
    );
};

export default DataTableAction;

import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import Tabs from "ui/Tabs";
import Tab from "ui/Tabs/Tab";
import InformationTab from "./InformationTab";
import KYCTab from "./KycTab";

const Informations: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            <Tabs>
                <Tab label={ intl.formatMessage("informations.tabs.information") } route="/informations">
                    <InformationTab />
                </Tab>
                <Tab label={ intl.formatMessage("informations.tabs.kyc") } route="/informations/kyc">
                    <KYCTab />
                </Tab>
            </Tabs>
        </React.Fragment>
    );
};

export default Informations;

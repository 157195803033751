import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";

interface Props {
    type: MangoKycRefusedType;
}

const KycRefusedType: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { type } = props;
    if (!type) {
        return null;
    }

    const lowerCased = type.toLowerCase();
    return (
        <React.Fragment>
            <span>{ intl.formatMessage('informations.kyc.mango.refusedType.' + lowerCased) }</span>
        </React.Fragment>
    );
};

export default KycRefusedType;

import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import TransactionService from "dataProvider/TransactionService";
import { FlashMessagesShape } from "context/FlashMessages";
import { IntlShape } from "context/Intl/Context";
import errorFormatter from "helpers/errorFormatter";

interface Props {
    onSubmit: () => void;
    onClose: () => void;
}

const getModalPayoutDisplayer = (intl: IntlShape, setModalStatus: any, flashMessages: FlashMessagesShape, setLoading: any) => {
    const onSubmit = (item: TransactionData) => {
        TransactionService.payout({
            funds: item.DebitedFunds,
            fees: item.Fees,
            debitedWalletId: item.DebitedWalletId,
        }).then((data: any) => {
            if (data.Status === 'FAILED') {
                throw data.ResultMessage;
            }
            console.log(data);

            flashMessages.addSuccess(intl.formatMessage("transactions.list.payout.success"));
        }).catch((reason) => {
            const errorMessage = errorFormatter(reason, 'error.E_PAYOUT_ERROR');
            flashMessages.addError(intl.formatMessage(errorMessage));
        }).finally(() => {
            setModalStatus(undefined); // Close modal
            setLoading('new'); // Force refresh
        });
    }

    return (item: TransactionData) => {
        setModalStatus({
            displayed: true,
            element: (
                <ModalPayout
                    onSubmit={ () => onSubmit(item) }
                    onClose={ () => setModalStatus(undefined) }
                />
            ),
        })
    }
}

const ModalPayout: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { onSubmit, onClose } = props;

    return (
        <React.Fragment>
            { intl.formatMessage("transactions.list.payout.confirmation") }

            <div className="modal-actions">
                <button className="button" onClick={ onClose }>{ intl.formatMessage("generic.cancel") }</button>
                <button className="button primary" onClick={ onSubmit }>{ intl.formatMessage("generic.confirm") }</button>
            </div>
        </React.Fragment>
    );
};

export {
    getModalPayoutDisplayer,
}

export default ModalPayout;

import React, { useContext } from "react";
import { IntlContext } from "context";

interface Props {
    type: MangoTransactionType;
}

const TransactionType: React.FC<Props> = (props: Props) => {
    const { type } = props;
    const intl = useContext(IntlContext);

    let icon = "";
    switch (type) {
        case "PAYIN":
            icon = "lnr-enter-down";
            break;

        case "TRANSFER":
            icon = "lnr-redo";
            break;

        case "PAYOUT":
            icon = "lnr-exit-up";
            break;

    }

    return (
        <span className="mango-transaction-type">
            <i className={ `lnr ${icon}` }></i>
            { intl.formatMessage('transactions.mango.type.' + type.toLocaleLowerCase()) }
        </span>
    );
};

export default TransactionType;

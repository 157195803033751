import { useEffect, useState } from "react";
import { useParams } from "react-router";

const useQueryPager = (setLoading: any, pageTag: string = "page"): [QueryParam, QueryParamSetter] => {
    const [ queryParams, setQueryParams ] = useState<QueryParam>();
    const params = useParams<any>();

    // When params.tag changes, we want to update the query parameters
    useEffect(() => {
        let newQueryParams = Object.assign({}, queryParams);
        newQueryParams.page = params[pageTag];
        setQueryParamsProxy(newQueryParams);

        // Disable the react warning : we know queryParams isn't part of it, it's on purpose.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params[pageTag]]);

    const setQueryParamsProxy = (setter: QueryParam) => {
        setLoading("new");

        setQueryParams(setter);
    }

    return [ queryParams!, setQueryParamsProxy ];
}

export default useQueryPager;

import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import Tabs from "ui/Tabs";
import Tab from "ui/Tabs/Tab";
import TransactionTab from "./TransactionTab";
import AccountTab from "./AccountTab";

const Tansactions: React.FC = () => {
    const intl = useContext(IntlContext);

    return (
        <React.Fragment>
            <Tabs>
                <Tab label={ intl.formatMessage("transactions.tabs.transactions") } route="/transactions/page/1">
                    <TransactionTab />
                </Tab>
                <Tab label={ intl.formatMessage("transactions.tabs.account") } route="/transactions/account">
                    <AccountTab />
                </Tab>
            </Tabs>
        </React.Fragment>
    );
};

export default Tansactions;

import React, { useState, useContext, useEffect  } from "react";
import { Form } from "react-final-form";
import { Context as IntlContext } from "context/Intl";
import { FlashMessagesProps, withFlashMessages } from "context/FlashMessages";
import getBase64 from "helpers/file/getBase64";
import InformationService from "dataProvider/InformationService";
import FileInterface from "dataProvider/FileInterface";
import TextField from "ui/Field/TextField";
import Select, {SelectOption} from "ui/Field/Select";
import Button from "ui/Field/Button";
import Files from "./Files";

import formatErrorResponse from "helpers/form/formatErrorResponse";

interface Props extends FlashMessagesProps {
    onSuccess: () => void;
}

const Modal: React.FC<any> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { onSuccess } = props;
    const [ file, setFile ] = useState<FileInterface|null>(null);
    const [ files, setFiles ] = useState<any[]>([]);

    const selectTypes: SelectOption[] = [
        { key: 'IDENTITY_PROOF', value: intl.formatMessage("informations.kyc.mango.type.identity_proof") },
        { key: 'ARTICLES_OF_ASSOCIATION', value: intl.formatMessage("informations.kyc.mango.type.articles_of_association") },
        { key: 'REGISTRATION_PROOF', value: intl.formatMessage("informations.kyc.mango.type.registration_proof") },
        { key: 'SHAREHOLDER_DECLARATION', value: intl.formatMessage("informations.kyc.mango.type.shareholder_declaration") },
    ];

    useEffect(() => {
        if (!file) {
            return;
        }

        if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
            props.flashMessages.addError(intl.formatMessage("informations.kyc.file.errorExt").replace('{file}', file.name));
            setFile(null);
            return;
        }

        if (file.size > 7 * 1024 * 1024) { // 7 Mo
            props.flashMessages.addError(intl.formatMessage("informations.kyc.file.errorSize").replace('{file}', file.name));
            setFile(null);
            return;
        }

        setFiles(files => [...files, file]);
        setFile(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, files]);

    const removeFile = (id:any) => {
        const tmp = files.filter((value, key) => {
            return key !== id
        });
        setFiles(tmp);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files ?? []
        if (files) {
            for(var f = 0; f<files.length; f++) {
                getBase64(e.target.files![f], setFile);
            }
        }
    }

    const onSubmit = async (values: {type_proof: string}) => {
        const data = Object.assign({}, {
            Type: values.type_proof,
            FileSize : file?.size,
            File : file?.content,
            FileType : file?.type,
            Files: files
        });

        try {
            const result = await InformationService.createDocument(data);
            console.log(result);
            onSuccess && onSuccess();
            props.flashMessages.addSuccess(intl.formatMessage("informations.kyc.modal.addProof.alert.success"));
        } catch (err:any) {
            if (err && err.response && err.response.data) {
                props.flashMessages.addError(intl.formatMessage("informations.kyc.modal.addProof.alert.error"));
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    return (
        <React.Fragment>
            <Form
            onSubmit={ onSubmit }
            render={ ({ handleSubmit, submitting }) => (
                <form onSubmit={ handleSubmit }>
                    <div className="grid grid has-1-columns">
                        <Select
                            id="type_proof"
                            name="type_proof"
                            label={ intl.formatMessage("informations.kyc.modal.addProof.label.type") }
                            placeholder={ intl.formatMessage("informations.kyc.modal.addProof.label.type") }
                            options={ selectTypes }
                            required
                        />

                        { file === null &&
                            <div className="grid grid has-2-columns">
                                <TextField
                                    id="attachment.file"
                                    name="file[]"
                                    label={ intl.formatMessage("informations.kyc.modal.addProof.label.file") }
                                    type="file"
                                    onChange={ handleChange }
                                    tooltip={ intl.formatMessage("informations.kyc.modal.addProof.label.fileInfos") }
                                    multiple
                                />
                            </div>
                        }

                        <Files files={ files } removeFile={ removeFile } />

                        <Button
                            type="submit"
                            disabled={ submitting }
                        >
                            { intl.formatMessage("informations.kyc.modal.addProof.label.submit") }
                        </Button>
                    </div>
                </form>
            ) }
        />

        </React.Fragment>
    );
};

export default withFlashMessages(Modal);

import React, { useContext } from "react";
import { AuthContext } from "context";
import { Redirect, Route, Switch } from "react-router";
import MinimalLayout from "components/Layout/Minimal";
import DoubleAuthentication from "./Authentication";
import MainLayout from "components/Layout/Main";
import Informations from "./Informations";
import Transactions from "./Transactions";
import LoaderPage from "components/App/LoaderPage";
import PaginationRoute from "components/Route/PaginationRoute";

const ModuleRouter: React.FC = () => {
    const auth = useContext(AuthContext);

    const renderAuth = () => {
        return (
            <MainLayout>
                <Switch>
                    <PaginationRoute path="/informations/kyc" component={ Informations } />
                    <Route path="/informations" component={ Informations } exact={ true } />

                    <Route path="/transactions/account" component={ Transactions } exact={ true } />
                    <PaginationRoute path="/transactions" component={ Transactions } />

                    <Redirect path="*" exact={ false } to="/informations" />
                </Switch>
            </MainLayout>
        );
    };

    const renderNoAuth = () => {
        return (
            <React.Fragment>
                <MinimalLayout>
                    <Route path="/login/:code" component={ DoubleAuthentication } exact={ true } />

                    <Route path="/" exact={ true } render={ () => ( null )} />
                </MinimalLayout>
            </React.Fragment>
        );
    };

    if (auth.loading) {
        return <LoaderPage />;
    }

    if (auth.isLoggedIn) {
        return renderAuth();
    }

    return renderNoAuth();
};

export default ModuleRouter;

import React from "react";

interface Props {
    amount: MangoMoney;
    fees?: MangoMoney;
}

const Money: React.FC<Props> = (props: Props) => {
    const { amount } = props;

    return (
        <div className="mango-money">
            <span className="mango-money-amount">{ amount.Amount / 100 } <span className="mango-amount-currency">{amount.Currency}</span></span>
        </div>
    );
};

export default Money;

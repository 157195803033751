import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import GridRenderer from "ui/GridRenderer";
import timestampToDate from "helpers/renderer/timestampToDate";

interface Props {
    userData: UserData;
}

const LegalDetails: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { userData } = props;

    const legalData = [
        { label: "legal.representativeLastname", content: userData.LegalRepresentativeLastName },
        { label: "legal.representativeFirstname", content: userData.LegalRepresentativeFirstName },
        { label: "legal.email", content: userData.LegalRepresentativeEmail },
        { label: "legal.nationality", content: userData.LegalRepresentativeNationality },
        { label: "legal.residence", content: userData.LegalRepresentativeCountryOfResidence },
        { label: "legal.birthday", content: userData.LegalRepresentativeBirthday, renderer: timestampToDate },

        { label: "address1", content: userData.LegalRepresentativeAddress.AddressLine1 },
        { label: "address2", content: userData.LegalRepresentativeAddress.AddressLine2 },
        { label: "city", content: [userData.LegalRepresentativeAddress.PostalCode, userData.LegalRepresentativeAddress.City].join(' ').trim() },
        { label: "region", content: userData.LegalRepresentativeAddress.Region },
        { label: "country", content: userData.LegalRepresentativeAddress.Country },

        { label: "legal.tag", content: userData.Tag },
    ];

    return (
        <React.Fragment>
            <div className="info-block">
                <h2>{ intl.formatMessage('informations.information.title.legal') }</h2>

                <GridRenderer
                    data={ legalData }
                    intlRoot={ "informations.information.label" }
                    gridClass={ "grid has-3-columns" }
                />
            </div>
        </React.Fragment>
    );
};

export default LegalDetails;

import React from "react";
import Loader from "ui/Loader";

type Props = {
    message?: string;
};

class LoaderPage extends React.Component<Props> {
    render() {
        const { message } = this.props;

        return (
            <div className="loader-page">
                <Loader message={ message } />
            </div>
        );
    }
}

export default LoaderPage;

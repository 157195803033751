import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import ErrorMessage from "components/Form/ErrorMessage";
import Tooltip from "ui/Field/Tooltip";

export type TextFieldProps = {
    name: string;
    defaultValue?: string;
    label?: string;
    fieldProps?: FieldProps<any, any>;
    multiline?: boolean;
    tooltip?: string;
    required?: boolean;
    [key: string]: any;
};

export default function TextField(props: Partial<TextFieldProps>) {
    const { name, defaultValue, fieldProps, ...rest } = props;

    return (
        <Field
            name={ name as string }
            render={({ input, meta }) =>
                <TextFieldWrapper input={ input } meta={ meta } { ...rest } />
            }
            defaultValue={ defaultValue }
            { ...fieldProps }
        />
    );
}

function TextFieldWrapper(props: FieldRenderProps<TextFieldProps, HTMLInputElement>) {
    const {
        input: { name, value, type = "text", onChange },
        meta,
        multiline,
        label,
        id,
        required,
        tooltip,
        ...rest
    } = props as any;

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    return (
        <div className="input-container">
            { label &&
                <React.Fragment>
                    <label htmlFor={ id }>
                        { label } { required && '*'}
                    </label>

                    { tooltip !== undefined &&
                        <Tooltip text={ tooltip } />
                    }
                </React.Fragment>
            }
            { multiline &&
                <textarea
                    name={ name }
                    value={ String(value) }
                    onChange={ onChange }
                    id={ id }
                    required={ required }
                    { ...rest }
                >
                </textarea>
            }
            { !multiline &&
                <input
                    name={ name }
                    type={ type }
                    value={ String(value) }
                    onChange={ onChange }
                    id={ id }
                    required={ required }
                    { ...rest }
                />
            }
            { showError &&
                <span className="input-container_error">
                    <ErrorMessage error={ meta.error || meta.submitError } />
                </span>
            }
        </div>
    );
}

import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import TransactionService, { RefundParameters } from "dataProvider/TransactionService";
import { FlashMessagesShape } from "context/FlashMessages";
import { IntlShape } from "context/Intl/Context";
import errorFormatter from "helpers/errorFormatter";

interface Props {
    onSubmit: () => void;
    onClose: () => void;
}

const getModalRefundDisplayer = (intl: IntlShape, setModalStatus: any, flashMessages: FlashMessagesShape, setLoading: any) => {
    const onSubmit = (item: TransactionData) => {
        // The params we send depends on the type of transaction we want to refund
        let params: RefundParameters = { type: "undefined" };
        if (item.Type === 'PAYIN') {
            params = { type: 'payin', payinId: item.Id };
        } else if (item.Type === 'TRANSFER') {
            params = { type: 'transfer', transferId: item.Id };
        }

        // If we couldn't find it, don't send the refund request
        if (params.type === 'undefined') {
            flashMessages.addError("error.E_REFUND_ERROR");
            return;
        }

        TransactionService.refund(params).then((data: any) => {
            if (data.Status === 'FAILED') {
                throw data.ResultMessage;
            }

            flashMessages.addSuccess(intl.formatMessage("transactions.list.refund.success"));
        }).catch((reason) => {
            const errorMessage = errorFormatter(reason, 'error.E_REFUND_ERROR');
            flashMessages.addError(intl.formatMessage(errorMessage));
        }).finally(() => {
            setModalStatus(undefined); // Close modal
            setLoading('new'); // Force refresh
        });
    }

    return (item: TransactionData) => {
        setModalStatus({
            displayed: true,
            element: (
                <ModalRefund
                    onSubmit={ () => onSubmit(item) }
                    onClose={ () => setModalStatus(undefined) }
                />
            ),
        })
    }
}

const ModalRefund: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { onSubmit, onClose } = props;

    return (
        <React.Fragment>
            { intl.formatMessage("transactions.list.refund.confirmation") }

            <div className="modal-actions">
                <button className="button" onClick={ onClose }>{ intl.formatMessage("generic.cancel") }</button>
                <button className="button primary" onClick={ onSubmit }>{ intl.formatMessage("generic.confirm") }</button>
            </div>
        </React.Fragment>
    );
};

export {
    getModalRefundDisplayer,
}

export default ModalRefund;

import fetch, { parseResponse } from "./fetch";

export interface PayoutParameters {
    funds: MangoMoney;
    fees: MangoMoney;
    debitedWalletId: string;
}

export interface RefundParameters {
    type: 'payin' | 'transfer' | 'undefined';
    payinId?: string;
    transferId?: string;
}

class Transaction {
    accounts() {
        return parseResponse(fetch.get(`transaction/account/`));
    }

    transactions(params?: QueryParam) {
        return parseResponse(fetch.get(`transaction/`, {
            params: params
        }));
    }

    payout(params: PayoutParameters) {
        return parseResponse(fetch.post(`transaction/payout/`, params));
    }

    refund(params: RefundParameters) {
        return parseResponse(fetch.post(`transaction/refund/`, params));
    }
}

export default new Transaction();

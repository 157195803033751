import { AxiosResponse } from "axios";
import fetch, { parseResponse } from "./fetch";

class Information {
    user() {
        return parseResponse(fetch.get(`information/user/`));
    }

    kyc(params?: QueryParam) {
        return parseResponse(fetch.get(`information/kyc/`, {
            params: params
        }));
    }

    createDocument(data: HashMap<any>): Promise<AxiosResponse<Document>> {
        return fetch.post(`information/document/`, data);
    }
}

export default new Information();

import React, { useContext } from "react";
import { IntlContext } from "context";

interface Props {
    status: MangoTransactionStatus;
}

const TransactionStatus: React.FC<Props> = (props: Props) => {
    const { status } = props;
    const intl = useContext(IntlContext);

    const lowerCased = status.toLowerCase();
    return (
        <React.Fragment>
            <span className={ `mango-transaction-status is-${lowerCased}` }>{ intl.formatMessage('transactions.mango.status.' + lowerCased) }</span>
        </React.Fragment>
    );
};

export default TransactionStatus;

import React, { useState, useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import InformationService from "dataProvider/InformationService";
import { getColumns, getRenderer } from "./Data";
import useQueryPager from "helpers/useQueryPager";
import Loader from "ui/Loader";
import DataTable from "ui/DataTable";
import Modal from "ui/Modal";
import AddModal from "./Modal";

const KycTab: React.FC = () => {
    const intl = useContext(IntlContext);

    const [ loading, setLoading ] = useState<LoadingStatus>("new");
    const [ queryParams, setQueryParams ] = useQueryPager(setLoading);
    const [ kycData, setKycData ] = useState<any>({});
    const [ isModalOpen, setModalOpen ] = useState<boolean>(false);

    if (loading === "new") {
        setLoading("loading");

        const data = InformationService.kyc(queryParams as QueryParam);
        data.then((data: any) => {
            setKycData(data);
            setLoading("success");
        }).catch(() => {
            setLoading("error");
        });
    }

    if (loading === "loading" || loading === "new") {
        return <Loader message={ 'Tentative de connexion...' } />;
    }

    if (loading === "error") {
        return <span>{ intl.formatMessage("error.generic") }</span>;
    }

    // Fetch the DataTable config from our file
    const columns = getColumns(intl);
    const renderer = getRenderer(intl);

    const closeModal = () => {
        console.log('onclose')
        setModalOpen(false);
    }
    return (
        <React.Fragment>
            <div>
                <div>
                    <div className="add-icon-button">
                        <span onClick={ () => setModalOpen(true) }>
                            <i className="lnr lnr-plus-circle"></i>
                            { intl.formatMessage("informations.kyc.list.action.add") }
                        </span>
                    </div>
                </div>

                <Modal
                    title={ intl.formatMessage("informations.kyc.title.uploadDocuments") }
                    display={ isModalOpen }
                    onClose={ () => closeModal() }
                    size={ 'small' }
                >
                    <AddModal onSuccess={ () => {
                        setLoading('new');
                        closeModal();
                    } } />
                </Modal>

                <DataTable
                    columns={ columns }
                    data={ kycData }
                    renderer={ renderer }
                    queryParams={ queryParams }
                    setQueryParams={ setQueryParams }
                />
            </div>
        </React.Fragment>
    );
};

export default KycTab;

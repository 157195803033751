import React from "react";
import fetch from "dataProvider/fetch";
import AuthenticationService, { AuthData } from "dataProvider/AuthenticationService";
import getIn from "helpers/getIn";
import { navigation, NavigationElement } from "helpers/navigation";
import Acl from "./Acl";

interface Client {
    id: string;
    nom: string;
    nom_usage?: string;
    logo_id: string | null;
}

interface Account {
    user: {
        id: string;
        gender: string;
        first_name: string;
        last_name: string;
        username: string;
        email: string;
        language_code: string;
        role: {
            id: string;
            name: string;
        };
    };
    acl: Acl;
    resources?: Array<Client>;
}

export interface AuthenticationProviderInterface extends Readonly<{}> {
    loading: boolean;
    isLoggedIn: boolean;
    account: Account | null;
    login: (authInfo: { username: string; password: string }) => Promise<void>;
    requestCode: (code: string) => Promise<void>;
    loginUsingCode: (code: string, login_code: string) => Promise<void>;
    logout: () => Promise<void>;
    getNavigation: () => NavigationElement[];
    isAllowed: (capability: string) => boolean;
    filterAllowed: (actions: HashMap<any>, acl: HashMap<string>) => HashMap<any>;
    updateAccount: () => Promise<void>;
}

export const ACCOUNT_KEY: string = "account";

function storeDataToAccount(data: AuthData): Account {
    const account = {
        user: getIn(data, "user"),
        acl: new Acl(),
        resources: getIn(data, "resources"),
    };

    if (data.capabilities.length > 0) {
        data.capabilities.forEach((capability) => {
            account.acl.allow(capability);
        });
    }

    return account;
}

function getAccount(): Account | null {
    if (fetch.sessionIsExpired()) {
        return null;
    }
    try {
        const account = localStorage.getItem(ACCOUNT_KEY);
        if (account) {
            return storeDataToAccount(JSON.parse(account));
        }
        return null;
    } catch (err) {
        return null;
    }
}

function isLoggedIn(): boolean {
    return !!getAccount() && !fetch.sessionIsExpired();
}

const AuthContext = React.createContext<AuthenticationProviderInterface>({} as AuthenticationProviderInterface);

class AuthProvider extends React.Component<Readonly<{}>, AuthenticationProviderInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            loading: true,
            isLoggedIn: false,
            account: null,
            login: this.login,
            requestCode: this.requestCode,
            loginUsingCode: this.loginUsingCode,
            logout: this.logout,
            getNavigation: this.getNavigation,
            isAllowed: this.isAllowed,
            filterAllowed: this.filterAllowed,
            updateAccount: this.updateAccount,
        };
    }

    componentDidMount = async () => {
        try {
            if (!isLoggedIn()) {
                return;
            }

            try {
                await this.updateAccount();
            } catch (e) {
                this.logout();
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    isAllowed = (capability: string) => {
        return !!this.state.account && this.state.account.acl.isAllowed(capability);
    };

    filterAllowed = (actions: HashMap<any>, acl: HashMap<string>) => {
        const actionsAllowed: HashMap<any> = {};
        for (const action in actions) {
            if (!acl[action]) {
                actionsAllowed[action] = actions[action];
            } else if (this.isAllowed(acl[action])) {
                actionsAllowed[action] = actions[action];
            }
        }
        return actionsAllowed;
    };

    login = async ({ username, password }: { username: string; password: string }) => {
        const data = await AuthenticationService.login(username, password);
        const account = Object.assign({}, data);
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ isLoggedIn: true, account: storeDataToAccount(account) });
    };

    requestCode = async (code: string) => {
        await AuthenticationService.requestCode(code);
    };

    loginUsingCode = async (code: string, login_code: string) => {
        const data = await AuthenticationService.loginUsingCode(code, login_code);
        const account = Object.assign({}, data);
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ isLoggedIn: true, account: storeDataToAccount(account) });
    };

    logout = async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const bugIE = await AuthenticationService.logout();
        } finally {
            localStorage.removeItem(ACCOUNT_KEY);
            localStorage.removeItem('login_code');
            fetch.clearSession();
            this.setState({ isLoggedIn: false, account: null });
            window.location.href = "/";
        }
    };

    getNavigation = () => {
        const _recursiveFilter = (array: NavigationElement[]) => {
            let results: NavigationElement[] = [];

            for (const navItem of array) {
                if (navItem.acl && !this.isAllowed(navItem.acl)) {
                    continue;
                }
                if (navItem.children) {
                    navItem.children = _recursiveFilter(navItem.children);
                }
                results.push(navItem);
            }

            return results;
        }

        const secureNav = _recursiveFilter(navigation(this.state));
        return secureNav;
    }

    updateAccount = async () => {
        let data: any;

        try {
            data = await AuthenticationService.fresh();
        } catch (e) {
            this.logout();
        }

        if (data !== undefined && data?.error !== undefined) {
            this.logout();
        }

        if (data !== undefined && data?.user !== undefined) {
            const account = Object.assign({}, data);
            localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
            this.setState({ isLoggedIn: true, account: storeDataToAccount(account) });
        }
    };

    render() {
        return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
    }
}

const Consumer = AuthContext.Consumer;

export { AuthProvider as Provider, Consumer, AuthContext as Context };

import React, {useContext} from "react";
import { Context as IntlContext } from "context/Intl";
interface Props {
    status: MangoKycStatus;
}

const KycStatus: React.FC<Props> = (props: Props) => {
    const { status } = props;
    const intl = useContext(IntlContext);

    const lowerCased = status.toLowerCase();
    return (
        <React.Fragment>
            <span className={ `mango-kyc-status is-${lowerCased}` }>{ intl.formatMessage(`informations.kyc.mango.status.${lowerCased}`)  }</span>
        </React.Fragment>
    );
};

export default KycStatus;

import React, { useContext } from "react";
import { useLocation, useParams } from "react-router";
import { Context as IntlContext } from "context/Intl";
import PaginationLink from "./PaginationLink";

interface Props {
    data: PaginatedResults<any>;
    pageTag?: string;
}

const DataTablePagination: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { data } = props;
    let { pageTag } = props;
    const params = useParams<any>();
    const location = useLocation();

    if (data.pages <= 1 && 0) {
        return null;
    }

    if (!pageTag) {
        pageTag = "page";
    }

    const currentPage = parseInt(params[pageTag] || 1);
    let slug = location.pathname.replace(new RegExp('/' + pageTag + '/\\d+/?$'), '');
    slug = slug.replace(/\/*$/, '') + '/';

    return (
        <div className="pagination">
            <ul className="pagination-nav">
                <li key={ 'pagination-first' }>
                    <PaginationLink
                        disabled={ currentPage === 1 }
                        text={ '<<' }
                        url={ slug }
                        tag={ pageTag }
                    />
                </li>

                <li key={ 'pagination-previous' }>
                    <PaginationLink
                        disabled={ currentPage === 1 }
                        text={ '<' }
                        url={ slug + pageTag + '/' + (currentPage - 1) }
                        tag={ pageTag }
                    />
                </li>

                <li
                    key={ 'pagination-currentPage' }
                    className="pagination-counter"
                >
                    <span className="pagination-counter-current">{ currentPage }</span>
                    <span> de </span>
                    <span>{ data.pages }</span>
                </li>

                <li key={ 'pagination-next' }>
                    <PaginationLink
                        disabled={ currentPage === data.pages }
                        text={ '>' }
                        url={ slug + pageTag + '/' + (currentPage + 1) }
                        tag={ pageTag }
                    />
                </li>

                <li key={ 'pagination-last' }>
                    <PaginationLink
                        key={ 'pagination-pager.last_page' }
                        disabled={ currentPage === data.pages }
                        text={ '>>' }
                        url={ slug + pageTag + '/' + data.pages }
                        tag={ pageTag }
                    />
                </li>
            </ul>

            <div className="pagination-total">
                { intl.formatMessage("generic.datalist.totalResults") }
                <span>{ data.total }</span>
            </div>
        </div>
    );
};

export default DataTablePagination;

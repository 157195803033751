import React from "react";
import ReactDOM from "react-dom";

export interface Props {
    title?: string;
    display?: boolean;
    size?: 'full' | 'medium' | 'small' | 'auto';
    closeWhenClickBackground?: boolean;
    onClose?: () => void;
    children?: any;
}

const Modal: React.FC<Props> = (props: Props) => {
    const {
        title = '',
        display = false,
        size = 'medium',
        closeWhenClickBackground = true,
        onClose,
        children
    } = props;

    const closeModal = () => {
        onClose && onClose();
    }

    const render = (
        <React.Fragment>
            { display &&
                <div
                    className="modal"
                    onClick={ () => {
                        !!closeWhenClickBackground && closeModal();
                    } }
                >
                    <div className={ `modal-wrapper ${size}` } onClick={ (e: React.MouseEvent) => { e.stopPropagation() } }>
                        <button className="modal-close" onClick={ closeModal }>
                            <i className={ 'lnr lnr-cross' }></i>
                        </button>

                        <h2>{ title }</h2>

                        <div className="modal-content">
                            { children }
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>

    );

    return ReactDOM.createPortal(render, document.getElementById("modal-root")!);
};

export default Modal;

import React from "react";
import { Link } from "react-router-dom";

interface Props {
    disabled: boolean;
    text: string;
    url: string;
    tag: string;
};

const PaginationLink: React.FC<Props> = ({ disabled, text, url, tag }) => {
    if (disabled) {
        return <span>{ text }</span>;
    }

    return <Link to={{ pathname: url }} >{ text }</Link>;
};

export default PaginationLink;

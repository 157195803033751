import React, { useContext, useState } from "react";
import TextField from "ui/Field/TextField";
import Button from "ui/Field/Button";
import { Form } from "react-final-form";
import { Context as AuthContext } from "context/Auth";
import { Context as IntlContext } from "context/Intl";
import formatErrorResponse from "helpers/form/formatErrorResponse";
import { useParams } from "react-router-dom";
import Loader from "ui/Loader";

const DoubleAuthentication: React.FC = () => {
    const [ sentRequest, setSentRequest ] = useState<boolean>(false);
    const [ acceptedRequest, setAcceptedRequest ] = useState<boolean>(false);
    const [ error, setError ] = useState<boolean>(false);
    const authData = useContext(AuthContext);
    const intl = useContext(IntlContext);

    const sendCodeRequest = (code: string) => {
        setSentRequest(true);

        authData.requestCode(code).then(() => {
            setAcceptedRequest(true);
        }).catch(() => {
            setError(true);
        });
    }

    const { code } = useParams<any>();
    if (!sentRequest && code) {
        const loginCode = localStorage.getItem('login_code');
        if (loginCode) {
            authData.loginUsingCode(code, loginCode).catch(() => {
                localStorage.removeItem('login_code');
                sendCodeRequest(code);
            });
        } else {
            sendCodeRequest(code);
        }
    }

    const onSubmit = async (values: { login_code: string }) => {
        try {
            await authData.loginUsingCode(code, values.login_code).then(() => {
                localStorage.setItem('login_code', values.login_code);
            });
        } catch (err: any) {
            if (err.response && err.response.data) {
                return formatErrorResponse(err.response.data);
            }
            throw err;
        }
    };

    if (acceptedRequest) {
        return (
            <Form
                onSubmit={ onSubmit }
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={ handleSubmit }>
                        <p>{ intl.formatMessage("authentication.login.enter_code") }</p>

                        <TextField
                            id="login-code"
                            name="login_code"
                            autoComplete="none"
                            placeholder={ intl.formatMessage("authentication.login.label.code") }
                            required
                        />

                        <div className="flex from-right">
                            <Button
                                type="submit"
                                className="button primary"
                                disabled={ submitting || pristine }
                            >
                                { intl.formatMessage("authentication.login.action.signIn") }
                            </Button>
                        </div>
                    </form>
                )}
            />
        );
    } else if (error) {
        return (
            <p>{ intl.formatMessage("authentication.login.code.error") }</p>
        );
    }

    return <Loader message={ 'Tentative de connexion...' } />;
};

export default DoubleAuthentication;

import { AuthenticationProviderInterface } from "context/Auth/Context";

export interface NavigationElement {
    label: string;
    skipIntl?: boolean;
    route?: string;
    onClick?: () => void;
    acl?: string;
    className?: string;
    children?: NavigationElement[];
};

export const navigation = (authData: AuthenticationProviderInterface): NavigationElement[] => {
    let loginChildren: NavigationElement[] = [
        {
            label: "menu.logout",
            onClick: () => {
                authData.logout();
            },
        },
    ];

    return [
        {
            label: "menu.informations",
            route: "/informations",
            acl: "*",
        },
        {
            label: "menu.transactions",
            route: "/transactions",
            acl: "*",
        },


        {
            label: `${authData.account?.user.first_name} ${authData.account?.user.last_name}`,
            skipIntl: true,
            className: 'right',
            children: loginChildren,
        },
    ]
}

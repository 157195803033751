import React from "react";

export type ButtonProps = {
    name: string;
    type?: "button" | "submit" | "reset";
    fullWidth?: boolean;
    [ key: string ]: any;
};

export default function Button(props: Partial<ButtonProps>) {
    const { name, type, fullWidth, onClick, className, ...rest } = props;

    let classes = ["button"];
    if (fullWidth) {
        classes.push("fullwidth");
    }
    if (className) {
        classes.push(className);
    }

    return (
        <button
            type={ type }
            onClick={ onClick }
            className={ classes.join(" ") }
            { ...rest }
        />
    );
}

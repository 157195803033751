import React, { Suspense } from "react";
import { Topbar } from "./components";
import LoaderPage from "components/App/LoaderPage";
import Bottombar from "./components/Bottombar";

const MainLayout: React.FC = (props) => {
    return (
        <React.Fragment>
            <Topbar />

            <div className="main">
                <Suspense fallback={ <LoaderPage /> }>
                    { props.children }
                </Suspense>
            </div>

            <Bottombar />
        </React.Fragment>
    );
};

export default MainLayout;

const getBase64 = (file: any, setFile: React.Dispatch<any>) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        const dataUrl: string = reader.result as string;
        let data = {
            content: dataUrl.replace(/^data:.+;base64,/, ""),
            name: file.name,
            size: file.size,
            type: file.type,
        }
        setFile(data);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export default getBase64

import React, { useState } from "react";
import { useHistory } from "react-router";

const Tabs: React.FC = ({ children }) => {
    const history = useHistory();

    let defaultTab = 0;
    if (Array.isArray(children)) {
        children.forEach((child: any, i: number) => {
            const regex = new RegExp('^' + child.props.route + '(/[a-z]+/[0-9]+/?)?');
            if (regex.test(history.location.pathname)) {
                defaultTab = i;
            }
        });
    }
    const [ currentTab, setCurrentTab ] = useState<number>(defaultTab);

    const setCurrentTabProxy = (index: number, route?: string) => {
        if (route) {
            history.push(route);
        }

        setCurrentTab(index);
    }

    return (
        <React.Fragment>
            { Array.isArray(children) &&
                <div className={ 'tabs' }>
                    <nav className={ 'tabs_nav' }>
                        <ul>
                            { children.map((child: any, index: number) => (
                                <React.Fragment key={ child.props.label }>
                                    <li
                                        className={ 'tabs_nav-item' + (index === currentTab ? ' current' : '') }
                                        onClick={ () => setCurrentTabProxy(index, child.props.route ) }
                                    >
                                        { child.props.label }
                                    </li>
                                </React.Fragment>
                            )) }
                        </ul>
                    </nav>
                    <div className={ 'tabs_content' }>
                        { children.map((child: any, index: number) => {
                            if (index !== currentTab) {
                                return undefined;
                            }

                            return child.props.children;
                        }) }
                    </div>
                </div>
            }
        </React.Fragment>
    );
};
export default Tabs;

import React from 'react';

export type TooltipProps = {
    text: string;
};

const Tooltip: React.FC<any> = ({text}) => {
    return (
        <span className="tooltip">
            <i className="lnr lnr-question-circle" title={ text }> </i>
        </span>
    );
};

export default Tooltip;
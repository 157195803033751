import React from "react";

interface Props {
    columnKey: string;
    queryParams: QueryParam;
    setQueryParams: QueryParamSetter;
}

const DataTableSorter: React.FC<Props> = (props: Props) => {
    const { columnKey, queryParams, setQueryParams } = props;

    const sort = (direction: string) => {
        let newQueryParams = Object.assign({}, queryParams) as QueryParam;
        newQueryParams.sort = columnKey;
        newQueryParams.order = direction;
        setQueryParams(newQueryParams);
    }

    const isAsc = queryParams.sort === columnKey && queryParams.order === 'asc' ? 'sort-current' : '';
    const isDesc = queryParams.sort === columnKey && queryParams.order === 'desc' ? 'sort-current' : '';

    return (
        <React.Fragment>
            <div className="sort">
                <i className={ `lnr lnr-chevron-down sort-item ${isDesc}` } onClick={ () => sort('desc') }></i>
                <i className={ `lnr lnr-chevron-up sort-item ${isAsc}` } onClick={ () => sort('asc') }></i>
            </div>
        </React.Fragment>
    );
};

export default DataTableSorter;

import React, { useContext, useState } from "react";
import { Context as IntlContext } from "context/Intl";
import Loader from "ui/Loader";
import TransactionService from "dataProvider/TransactionService";
import DataTable from "ui/DataTable";
import { FlashMessagesProps, withFlashMessages } from "context/FlashMessages";
import Modal from "ui/Modal";
import { getModalPayoutDisplayer } from "./ModalPayout";
import { getActions, getColumns, getRenderer } from "./Data";
import useQueryPager from "helpers/useQueryPager";
import { getModalRefundDisplayer } from "./ModalRefund";

interface Props extends FlashMessagesProps {}

interface ModalStatus {
    displayed: boolean;
    element: any;
}

const AccountTab: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const [ loading, setLoading ] = useState<LoadingStatus>("new");
    const [ queryParams, setQueryParams ] = useQueryPager(setLoading);
    const [ transactionData, setTransactionData ] = useState<PaginatedResults<TransactionData>>();
    const [ modalStatus, setModalStatus ] = useState<ModalStatus>();

    // Load the data, with the pagination informations from the route
    if (loading === "new") {
        setLoading("loading");

        const data = TransactionService.transactions(queryParams as QueryParam);
        data.then((data: any) => {
            setTransactionData(data);
            setLoading("success");
        }).catch(() => {
            setLoading("error");
        });
    }

    if (loading === "loading" || loading === "new") {
        return <Loader message={ intl.formatMessage("generic.loading") } />;
    }

    if (loading === "error" || !transactionData) {
        return <span>{ intl.formatMessage("error.generic") }</span>;
    }

    // Payout Modal action.
    const displayModalPayout = getModalPayoutDisplayer(intl, setModalStatus, props.flashMessages, setLoading);
    // Refund Modal action.
    const displayModalRefund = getModalRefundDisplayer(intl, setModalStatus, props.flashMessages, setLoading);

    // Fetch the DataTable config from our file
    const columns = getColumns(intl);
    const renderer = getRenderer(intl);
    const actions = getActions(intl, displayModalPayout, displayModalRefund);

    return (
        <React.Fragment>
            <Modal
                display={ modalStatus?.displayed }
                size="auto"
                onClose={ () => setModalStatus(undefined) }
            >
                { modalStatus?.element }
            </Modal>

            <DataTable
                columns={ columns }
                data={ transactionData }
                renderer={ renderer }
                actions={ actions }
                queryParams={ queryParams }
                setQueryParams={ setQueryParams }
            />
        </React.Fragment>
    );
};

export default withFlashMessages(AccountTab);

import React from "react";

type Props = {
    message?: string;
};

class Loader extends React.Component<Props> {
    render() {
        return (
            <div className="loader-wrapper">
                <div className="loader-item" />
                <div className="text-center">
                    { this.props.message ? <p className="loader-text">{ this.props.message }</p> : null }
                </div>
            </div>
        );
    }
}

export default Loader;

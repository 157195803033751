import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import ErrorMessage from "components/Form/ErrorMessage";

export type SelectOption = {
    key: string;
    value: string;
}

export type SelectProps = {
    name: string;
    multiple?: boolean;
    options: SelectOption[] | (() => SelectOption[]);
    placeholder?: string;
    fieldProps?: FieldProps<any, any>;
    [key: string]: any;
};

export default function Select(props: Partial<SelectProps>) {
    const { name, fieldProps, ...rest } = props;

    return (
        <Field
            type="select"
            name={ name as string }
            render={({ input, meta }) =>
                <SelectWrapper input={ input } meta={ meta } { ...rest } />
            }
            { ...fieldProps }
        />
    );
}

function SelectWrapper(props: FieldRenderProps<SelectProps, HTMLInputElement>) {
    const {
        input,
        name,
        meta,
        options,
        placeholder,
        label,
        required,
        id,
        ...rest
    } = props as any;

    let optionsObject: SelectOption[];
    if (typeof options === 'function') {
        optionsObject = options();
    } else {
        optionsObject = options;
    }

    if (placeholder && (optionsObject[0] && optionsObject[0].key !== '')) {
        optionsObject.unshift({ key: '', value: placeholder });
    }

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    const { value, ...selectInput } = input;

    return (
        <div className="input-container">
            { label &&
                <label htmlFor={ id }>
                    { label }
                </label>
            }
            <select
                defaultValue={ value }
                required={ required }
                id={ id }
                { ...selectInput }
                { ...rest }
            >
                { optionsObject.map((option: SelectOption) =>
                    <option
                        key={ `${name}__option__${option.key}` }
                        value={ option.key }
                    >{ option.value }</option>)
                }
            </select>
            { showError && <ErrorMessage error={ meta.error || meta.submitError } /> }
        </div>
    );
}

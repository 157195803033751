import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import GridRenderer from "ui/GridRenderer";

interface Props {
    accountData: AccountData;
}

const AccountDetails: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { accountData } = props;

    let address = (accountData.OwnerAddress.AddressLine1 ? accountData.OwnerAddress.AddressLine1 : "") + ", ";
    address+= accountData.OwnerAddress.City;

    let data = [
        { label: "account.name", content: accountData.OwnerName },
        { label: "account.address", content: address.trim() },
    ];

    switch (accountData.Type) {
        case 'CA':
            data.push({ label: "account.accountNumber", content: (accountData as AccountCAData).AccountNumber });
            data.push({ label: "account.bankName", content: (accountData as AccountCAData).BankName });
            data.push({ label: "account.branchCode", content: (accountData as AccountCAData).BranchCode });
            data.push({ label: "account.institutionNumber", content: (accountData as AccountCAData).InstitutionNumber });
            break;

        case 'GB':
            data.push({ label: "account.accountNumber", content: (accountData as AccountGBData).AccountNumber });
            data.push({ label: "account.sortCode", content: (accountData as AccountGBData).SortCode });
            break;

        case 'IBAN':
            let iban = (accountData as AccountIBANData).IBAN;
            iban = iban.substr(0, 2) + "*".repeat(iban.length - 4) + iban.substr(iban.length - 2);
            data.push({ label: "account.iban", content: iban });
            break;

        case 'OTHER':
            data.push({ label: "account.accountNumber", content: (accountData as AccountOTHERData).AccountNumber });
            data.push({ label: "account.bic", content: (accountData as AccountOTHERData).BIC });
            data.push({ label: "account.country", content: (accountData as AccountOTHERData).Country });
            break;

        case 'US':
            data.push({ label: "account.accountNumber", content: (accountData as AccountUSData).AccountNumber });
            data.push({ label: "account.aba", content: (accountData as AccountUSData).ABA });
            data.push({ label: "account.depositAccountType", content: (accountData as AccountUSData).DepositAccountType });
            break;
    }

    return (
        <React.Fragment>
            <div className="info-block">
                <h2>{ intl.formatMessage('transactions.account.title.accountType.' + accountData.Type) }</h2>

                <GridRenderer
                    data={ data }
                    intlRoot={ "transactions.account.label" }
                    gridClass={ "" }
                />
            </div>
        </React.Fragment>
    );
};

export default AccountDetails;

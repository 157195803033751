import React from "react";
import FileInterface from "dataProvider/FileInterface";

const Files: React.FC<any> = ({ files = [], removeFile }) => {
      return (
        <div className="grid grid has-2-columns mango-files">
           { files.map((file: FileInterface, index: number) => {
               return (
                   <div className="mango-file" key={ index }>
                        <i className={ 'lnr lnr-file-empty' }></i>
                        <div>{ file.name }</div>
                        <i className={ 'lnr lnr-trash' } onClick={ () => removeFile(index) }></i>
                    </div>
               );
           }) }
        </div>
    );
};

export default Files;

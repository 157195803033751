import React, { Suspense } from "react";
import { Provider as AuthProvider } from "context/Auth";
import { Provider as IntlProvider } from "context/Intl";
import { Provider as FlashMessagesProvider } from "context/FlashMessages";
import { BrowserRouter } from "react-router-dom";
import FlashMessages from "components/App/FlashMessages";
import ErrorBoundary from "components/App/ErrorBoundary";
import LoaderPage from "components/App/LoaderPage";
import ModuleRouter from "./ModuleRouter";

import "assets/sass/main.scss";

const App: React.FC = () => {
    return (
        <AuthProvider>
            <IntlProvider>
                <ErrorBoundary>
                    <FlashMessagesProvider FlashComponent={ FlashMessages }>
                        <BrowserRouter basename={ process.env.REACT_APP_BASE_PATH }>
                            <Suspense fallback={ <LoaderPage /> }>
                                <ModuleRouter />
                            </Suspense>
                        </BrowserRouter>
                    </FlashMessagesProvider>
                </ErrorBoundary>
            </IntlProvider>
        </AuthProvider>
    );
};

export default App;

import React from "react";
import { Action, Column } from ".";
import DataTableSorter from "./Sorter";

interface Props {
    columns: Column[];
    actions?: Action[];
    queryParams?: QueryParam;
    setQueryParams?: QueryParamSetter;
}

const DataTableHeader: React.FC<Props> = (props: Props) => {
    const { columns, actions, queryParams, setQueryParams } = props;

    return (
        <React.Fragment>
            { columns.length &&
                <thead>
                    <tr>
                        { columns.map((column) => {
                            let sorter = null;
                            if (column.sortable && queryParams && setQueryParams) {
                                sorter = <DataTableSorter columnKey={ column.key } queryParams={ queryParams } setQueryParams={ setQueryParams } />;
                            }

                            return <th key={ column.key }>{ column.label }{ sorter }</th>
                        }) }

                        { (actions && !!actions.length) &&
                            <th></th>
                        }
                    </tr>
                </thead>
            }
        </React.Fragment>
    );
};

export default DataTableHeader;

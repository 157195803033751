import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";

interface Props {
    data: any[];
    intlRoot: string;
    gridSize?: number;
    gridClass?: string;
    displayIfNull?: boolean;
    labelWhenNull?: string;
}

interface RenderItem {
    label: string;
    content: string;
    renderer?: (Intl: any, value: string) => string;
}

const GridRenderer: React.FC<Props> = (props: Props) => {
    const { data, gridSize, intlRoot, gridClass, displayIfNull } = props;
    const intl = useContext(IntlContext);

    let { labelWhenNull } = props;
    if (!labelWhenNull) {
        labelWhenNull = "\u00a0";
    }

    return (
        <div className={ `grid-renderer ${gridClass}` }>
            { data.map((item: RenderItem) => {
                let content = item.content;
                if (item.renderer) {
                    content = item.renderer(intl, content);
                }

                return <React.Fragment key={ Math.random() }>
                    { (displayIfNull || item.content) &&
                        <div className={ `grid-renderer-cell grid-${gridSize}` }>
                            <label>{ intl.formatMessage(`${intlRoot}.${ item.label }`) }</label>
                            <div className={ !content && labelWhenNull ? 'is-empty' : '' }>
                                { content ? content : labelWhenNull }
                            </div>
                        </div>
                    }
                </React.Fragment>
            })}
        </div>
    );
}

GridRenderer.defaultProps = {
    data: [],
    gridSize: 1,
    gridClass: "grid",
    displayIfNull: true,
}

export default GridRenderer;

import { IntlShape } from "context/Intl/Context";
import timestampToDate from "helpers/renderer/timestampToDate";
import KycRefusedType from "ui/Mango/KycRefusedType";
import KycStatus from "ui/Mango/KycStatus";
import KycTypeDocument from "ui/Mango/KycTypeDocument";

// Defines which colyumns to display
const getColumns = (intl: IntlShape) => {
    return [
        { key: "Type", label: intl.formatMessage("informations.kyc.list.label.type") },
        { key: "Status", label: intl.formatMessage("informations.kyc.list.label.status") },
        { key: "RefusedReasonType", label: intl.formatMessage("informations.kyc.list.label.refusedReason") },
        { key: "CreationDate", label: intl.formatMessage("informations.kyc.list.label.creationDate"), sortable: true },
        { key: "ProcessDate", label: intl.formatMessage("informations.kyc.list.label.processDate") },
    ];
}

// Set how the different columns will render
const getRenderer = (intl: IntlShape) => {
    return (key: any, datas: any) => {
        switch (key) {
            case "Type":
                return <KycTypeDocument typeDocument={ datas.Type } />;

            case "Status":
                return <KycStatus status={ datas.Status }>{ datas.Status }</KycStatus>;

            case "RefusedReasonType":
                return <KycRefusedType type={ datas.RefusedReasonType } />

            case "CreationDate":
                return timestampToDate(intl, datas.CreationDate, {
                    dateStyle: "long",
                    timeStyle: "short",
                });

            case "ProcessDate":
                return timestampToDate(intl, datas.CreationDate, {
                    dateStyle: "long",
                    timeStyle: "short",
                });

        }

        return datas[key as keyof TransactionData];
    }
}

export {
    getRenderer,
    getColumns,
};

import React from "react";

interface Props {
    href?: string;
    subject?: string;
    children: any;
}

const RequestChangeButton: React.FC<Props> = (props: Props) => {
    const { href, subject, children } = props;

    const subjectString = subject ? '?subject=' + subject : '';

    return (
        <div className="request-change-button">
            <a href={ `${href}${subjectString}` }>
                { children }
            </a>
        </div>
    );
}

RequestChangeButton.defaultProps = {
    href: "mailto:marketingmoto@suzuki.fr",
};

export default RequestChangeButton;

import React, {useContext} from "react";
import { Context as IntlContext } from "context/Intl";
interface Props {
    typeDocument: MangoKycTypeDocument;
}

const KycTypeDocument: React.FC<Props> = (props: Props) => {
    const { typeDocument } = props;
    const intl = useContext(IntlContext);

    const lowerCased = typeDocument.toLowerCase();
    return (
        <React.Fragment>
            <span>{ intl.formatMessage(`informations.kyc.mango.type.${lowerCased}`)  }</span>
        </React.Fragment>
    );
};

export default KycTypeDocument;

import { FormatDateOptions } from "@formatjs/intl";
import { IntlShape } from "context/Intl/Context";

export default function timestampToDate(intl: IntlShape, timestamp: number, options?: FormatDateOptions) {
    if (!options) {
        options = {
            dateStyle: "long",
        };
    }
    return intl.formatDate(new Date(timestamp * 1000), options);
}

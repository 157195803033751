import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import GridRenderer from "ui/GridRenderer";

interface Props {
    userData: UserData;
}

const CompanyDetails: React.FC<Props> = (props: Props) => {
    const intl = useContext(IntlContext);
    const { userData } = props;

    const companyData = [
        { label: "company.name", content: userData.Name },
        { label: "company.number", content: userData.CompanyNumber },
        { label: "company.email", content: userData.Email },
        { label: "address1", content: userData.HeadquartersAddress.AddressLine1 },
        { label: "address2", content: userData.HeadquartersAddress.AddressLine2 },
        { label: "city", content: [userData.HeadquartersAddress.PostalCode, userData.HeadquartersAddress.City].join(' ').trim() },
        { label: "region", content: userData.HeadquartersAddress.Region },
        { label: "country", content: userData.HeadquartersAddress.Country },
    ];

    return (
        <React.Fragment>
            <div className="info-block">
                <h2>{ intl.formatMessage('informations.information.title.company') }</h2>

                <GridRenderer
                    data={ companyData }
                    intlRoot={ "informations.information.label" }
                    gridClass={ "grid has-2-columns" }
                />
            </div>
        </React.Fragment>
    );
};

export default CompanyDetails;

import React from "react";
import ErrorMessage from "components/Form/ErrorMessage";

const formatErrorResponse = (responseData: { data: any }) => {
    const errors = {} as HashMap<any>;
    if (responseData && responseData.data) {
        const data = responseData.data;
        let split = null;
        Object.keys(data).forEach(key => {
            if (key.indexOf(".") !== -1) {
                split = key.split(".");
                split.reduce((acc, current, index) => {
                    if (index === split.length - 1) {
                        acc[current] = data[key];
                    } else {
                        acc[current] = acc[current] || {};
                    }
                    return acc[current];
                }, errors);
            } else {
                errors[key] = React.createElement(ErrorMessage, { error: data[key] });
            }
        });
    }
    return errors;
};

export default formatErrorResponse;

import React, { useContext, useState } from "react";
import { Context as IntlContext } from "context/Intl";
import Loader from "ui/Loader";

import TransactionService from "dataProvider/TransactionService";
import AccountDetails from "./AccountDetails";
import RequestChangeButton from "ui/RequestChangeButton";


const AccountTab: React.FC = () => {
    const intl = useContext(IntlContext);
    const [ loading, setLoading ] = useState<LoadingStatus>("new");
    const [ accountData, setAccountData ] = useState<AccountData[]>([]);

    if (loading === "new") {
        setLoading("loading");

        const data = TransactionService.accounts();
        data.then((data: any) => {
            setAccountData(data);
            setLoading("success");
        }).catch(() => {
            setLoading("error");
        });
    }

    if (loading === "loading" || loading === "new") {
        return <Loader message={ intl.formatMessage("generic.loading") } />;
    }

    if (loading === "error") {
        return <span>{ intl.formatMessage("error.generic") }</span>;
    }

    return (
        <React.Fragment>
            <div>
                <div className="grid has-3-columns">
                    { accountData.map((account: AccountData) => {
                        return <AccountDetails key={ Math.random() } accountData={ account } />
                    }) }
                </div>
            </div>

            <RequestChangeButton subject="BO e-commerce - Demande de modification - Comptes bancaires associés">
                { intl.formatMessage("generic.requestChange") }
            </RequestChangeButton>
        </React.Fragment>
    );
};

export default AccountTab;
